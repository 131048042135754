span.twitter-typeahead .tt-menu, span.twitter-typeahead .tt-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 1rem;
  color: #373a3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

span.twitter-typeahead .tt-suggestion {
  display: block;
  width: 100%;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
   span.twitter-typeahead .tt-suggestion:focus, .dropdown-item:hover, span.twitter-typeahead .tt-suggestion:hover {
    color: #2b2d2f;
    text-decoration: none;
    background-color: #f5f5f5; }
  span.twitter-typeahead .active.tt-suggestion, span.twitter-typeahead .tt-suggestion.tt-cursor, .dropdown-item.active:focus, span.twitter-typeahead .active.tt-suggestion:focus, span.twitter-typeahead .tt-suggestion.tt-cursor:focus, .dropdown-item.active:hover, span.twitter-typeahead .active.tt-suggestion:hover, span.twitter-typeahead .tt-suggestion.tt-cursor:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0275d8;
    outline: 0; }
  span.twitter-typeahead .disabled.tt-suggestion, .dropdown-item.disabled:focus, span.twitter-typeahead .disabled.tt-suggestion:focus, .dropdown-item.disabled:hover, span.twitter-typeahead .disabled.tt-suggestion:hover {
    color: #818a91; }
  span.twitter-typeahead .disabled.tt-suggestion:focus, .dropdown-item.disabled:hover, span.twitter-typeahead .disabled.tt-suggestion:hover {
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
    background-image: none;
    filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)"; }
span.twitter-typeahead {
  width: 100%; }
  .input-group span.twitter-typeahead {
    display: block !important; }
    .input-group span.twitter-typeahead .tt-dropdown-menu {
      top: 32px !important; }
  .input-group.input-group-lg span.twitter-typeahead .tt-dropdown-menu {
    top: 44px !important; }
  .input-group.input-group-sm span.twitter-typeahead .tt-dropdown-menu {
    top: 28px !important; }
