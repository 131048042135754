/* text pri objeektoch */
.bg-object-class-1, .bg-object-class-2, .bg-object-class-3, .bg-object-class-4, .bg-object-class-5, .bg-object-class-6,
.bg-object-class-7, .bg-object-class-8, .bg-object-class-9, .bg-object-class-10, .bg-object-class-11, .bg-object-class-12,
.bg-object-class-13 {
  color: #000 !important;
}

/*
.bg-object-class-1 a, .bg-object-class-2 a, .bg-object-class-3 a, .bg-object-class-4 a, .bg-object-class-5 a, .bg-object-class-6 a,
.bg-object-class-7 a, .bg-object-class-8 a, .bg-object-class-9 a, .bg-object-class-10 a, .bg-object-class-11 a {
  color: #000;
}

 */

/*
 * KULTÚRA, ŠPORT, TERMÁLNE KÚPALISKÁ + LYZIARSKE STREDISKA + ZOO
 */
.bg-object-class-1, .bg-object-class-9, .bg-object-class-10, .bg-object-class-12 {
  background-color: darken(rgb(182, 218, 156), 0%);
}

/*
 * UBYTOVACIE ZARIADENIA - HOTELY, PENZIÓNY, CHATY, REKREAČNÉ ZARIADENIA
 */
.bg-object-class-2 {
  background-color: darken(rgb(248, 172, 188), 0%);
}

/*
 * STRAVOVACIE ZARIADENIA - REŠTAURÁCIE, PIZZÉRIE, VINÁRNE
 */
.bg-object-class-3 {
  background-color: darken(rgb(157, 149, 185), 0%);
}

/*
 * REGIÓNY, SĺDLA
 */
.bg-object-class-4 {
  background-color: darken(rgb(147, 190, 230), 0%);
}

/*
 * KÚPELE
 */
.bg-object-class-5 {
  background-color: darken(rgb(124, 204, 191), 0%);
}

/*
 * CESTOVNÉ KANCELÁRIE + FIRMY + VYSTAVY
 */
.bg-object-class-6, .bg-object-class-11 {
  background-color: darken(rgb(255, 246, 133), 0%);
}

/*
 * ZABAVA
 */
.bg-object-class-13 {
  background-color: darken(rgb(255, 255, 255), 0%);
}

.map {
  @extend .img-thumbnail;
  width:100%;
  height:400px;
}

.card-object-small {
  @extend .card;
}

.card-object-small .card-header {
  font-size: 0.8em;
  font-weight: normal;
}

.card-object-small .card-footer {
  font-size: 0.7em;
}

.card-object-small .card-header {
  color: #fff;
}

.card-object-classic {
  margin-left: -1em;
  margin-right: -1em;
  border:0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #fff;
}

.card-object-classic .card-header {
  border:0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.card-object-classic .card-header {
  color: #fff;
}

.card-object-classic a {
  color: #002 !important;
}

.card-object-gallery a:hover {
  text-decoration: none !important;
}

.card-object-gallery h5 {
  font-size: 0.7em !important;
  color: $black;
  text-align: center;
}
