body {
  min-height: 100%;
  min-width: 100%;
  background-size: cover;
  // background: url('../images/background-summer-small.jpg') no-repeat fixed;
  background: url('../images/main-menu-background-light.jpg') no-repeat fixed;
  overflow-x: hidden;
}

#quick_results {
  min-height: 100%;
  min-width: 100%;
  background-size: cover;
  background: url('../images/main-menu-background-light.jpg') no-repeat fixed;
  position:absolute;
  color: $black;
}

#quick_results a:hover {
  text-decoration: none !important;
}

.navbar-main-menu {
  min-height: 100%;
  min-width: 100%;
  background-size: cover;
  //background: url('../images/main-menu-background.jpg') no-repeat fixed;
  background-color: $navbar-background-color;
}

.page-footer {
  min-height: 100%;
  min-width: 100%;
  background-size: cover;
  //background: url('../images/main-menu-background.jpg') no-repeat fixed;
  background-color: $navbar-background-color;
  color: $white;
}

.bg-map-light {
  min-height: 100%;
  min-width: 100%;
  background-size: cover;
  background: url('../images/main-menu-background-light.jpg') no-repeat fixed;
}

h2 {
  @extend .mt-4;
  @extend .mb-3;
}

.btn-link-block {
  @extend .btn;
  @extend .btn-block;
  white-space: normal !important;
  height: 100% !important;
}

.carousel-caption-style {
  @extend .shadow;
  @extend .p-2;
  @extend .bg-white;
  @extend .text-dark;
  @extend .rounded;
  background-color: rgba($white, .7) !important;
}

#bs-navbar-search {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noflexwrap {
  flex-wrap: nowrap !important;
}

.noflexwrap-all,
.noflexwrap-all * {
  flex-wrap: nowrap !important;
}

.notextwrap {
  white-space: nowrap !important;
}

.notextwrap-all,
.notextwrap-all * {
  white-space: nowrap !important;
}

.main-menu .dropdown-item {
  margin-right: 40px !important;
}

.main-menu .badge {
  margin-top: 4px !important;
}

.bg-main {
  background-color: $navbar-main-background !important
}

/*
a.bg-main:focus, a.bg-main:hover, button.bg-main:focus, button.bg-main:hover {
  background-color: #2d5986 !important
}
*/

// White links against a dark background
.navbar-main {
  .navbar-brand {
    color: $navbar-main-active-color;

    @include hover-focus {
      color: $navbar-main-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-main-color;

      @include hover-focus {
        color: $navbar-main-hover-color;
      }

      &.disabled {
        color: $navbar-main-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-main-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-main-color;
    border-color: $navbar-main-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-main-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-main-color;
    a {
      color: $navbar-main-active-color;

      @include hover-focus {
        color: $navbar-main-active-color;
      }
    }
  }
}

// Dark links against a dark background
.navbar-white {
  .navbar-brand {
    color: $navbar-light-active-color;

    @include hover-focus {
      color: $navbar-light-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-light-color;
    a {
      color: $navbar-light-active-color;

      @include hover-focus {
        color: $navbar-light-active-color;
      }
    }
  }
}
