// Bootstrap Your variable overrides
$navbar-light-color: #000;  // farba textu pre svetly navbar
$navbar-background-color: #002D4F;
/*
$theme-colors: (
        "white": #fff, // napriklad pre btn-white
        "object-class-1": #51a151, // KULTÚRA
        "object-class-2": #51a151, // UBYTOVACIE ZARIADENIA, REKREAČNÉ ZARIADENIA
        "object-class-3": #a859c4, // REŠTAURÁCIE, PIZZÉRIE, VINÁRNE
        "object-class-4": #5288c7, // REGIÓNY, SÍDLA
        "object-class-5": #4b9689, // KÚPELE
        "object-class-6": #cb6a3b, // CESTOVNÉ KANCELÁRIE
        "object-class-8": #cb6a3b, // FIRMY
        "object-class-9": #51a151, // ŠPORT, TERMÁLNE KÚPALISKÁ
        "object-class-10": #51a151, // LYŽIARSKE STREDISKÁ
        "object-class-11": #cb6a3b, // VÝSTAVY
);
 */

@import '~font-awesome/scss/font-awesome';
@import "~bootstrap/scss/bootstrap";
@import "~ekko-lightbox/dist/ekko-lightbox.css";
@import "~typeahead.js-bootstrap4-css/typeaheadjs.css";
@import 'variables.scss';
@import 'layout.scss';
@import 'objekty.scss';
@import "routePlanner.scss";


